
import Vue from 'vue';
import AtomText from '@/components/atoms/AtomText.vue';
import AtomSvgIcon from '@/components/atoms/AtomSvgIcon.vue';
import OrganismPageSubHeader from '@/components/organisms/page-header/OrganismPageSubHeader.vue';
import MoleculeCard, { CardVariant } from '@/components/molecules/cards/MoleculeCard.vue';

import isEnvReady, { Features } from '@/services/utilities/EnvFeatureService';
import RouteNamesEnum from '@/router/RouteNamesEnum';

export default Vue.extend({
  name: 'TemplateSettings',
  components: {
    AtomSvgIcon,
    AtomText,
    OrganismPageSubHeader,
    MoleculeCard,
  },
  data: () => ({
    CardVariant,
    Features,
    RouteNamesEnum,
  }),
  methods: {
    isEnvReady,
  },
});
